import { graphql } from 'gatsby';
import React from 'react';
import { CollectionTemplate } from '@matix/gatsby-theme-shopify';
const PageQuery = (props) => {
   return <CollectionTemplate {...props} />;
};

export const pageQuery = graphql`
   query collectionPageTemplateShopifyThemeCore($locale: String, $uuid: String) {
      pageQuery: matixShopifyCollection(frontmatter: { uuid: { eq: $uuid } }, locale: { eq: $locale }) {
         id
         locale
         creationDate
         modificationDate
         ...ProductCollectionThemeShopify
      }
      pageQueryLocale: allMatixMdx(filter: { frontmatter: { uuid: { eq: $uuid } } }) {
         nodes {
            ...PageQueryLocaleThemeCore
         }
      }
   }
`;

export default PageQuery;
